<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card v-if="initStorefront">
        <md-card-header data-background-color="blue" class="card-header">
          <div class="card-header-info">
            <h4 class="title">Изменить витрину</h4>
          </div>
        </md-card-header>
        <ValidationObserver ref="editStorefrontForm" :slim="true">
          <md-card-content>
            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Название</label>
                <md-input v-model.trim="title" @focus="reset" />
              </md-field>
            </ValidationProvider>

            <div v-if="cover && typeof cover == 'string'" class="image-wrapper">
              <img class="image" :src="cover" alt="Icon" />
              <span class="remove-icon" @click="cover = null">
                <md-icon>close</md-icon>
                <md-tooltip md-direction="top">Удалить</md-tooltip>
              </span>
            </div>

            <ValidationProvider
              v-else
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Иконка</label>
                <md-file
                  v-model="iconName"
                  accept="image/*"
                  @md-change="
                    cover = $event[0];
                    reset();
                  "
                />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Ключ</label>
                <md-input v-model.trim="key" @focus="reset" />
              </md-field>
            </ValidationProvider>

            <md-field>
              <label>Время жизни товаров (в днях)</label>
              <md-input
                v-model="lifetime"
                type="number"
                min="1"
                @change="validateLifetime"
              />
            </md-field>

            <div class="buttons-wrap">
              <md-button class="md-raised md-success" @click="editStorefront">
                Сохранить
              </md-button>
            </div>
          </md-card-content>
        </ValidationObserver>
      </md-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      title: "",
      cover: "",
      key: "",
      lifetime: "",
      iconName: "", // hack for vee-validate
    };
  },

  computed: {
    ...mapState({
      initStorefront: (state) => state.storefronts.storefront,
    }),
  },

  async mounted() {
    this.$store.commit("SET_SHOW_LOADER", true);
    let success = await this.getStorefront(+this.$route.params.id);
    if (success) {
      this.title = this.initStorefront.title;
      this.cover = this.initStorefront.cover.slice(
        this.initStorefront.cover.indexOf("/storage")
      );
      this.key = this.initStorefront.key;
      this.lifetime = +this.initStorefront.parameters.value;
    }
    this.$store.commit("SET_SHOW_LOADER", false);
  },

  methods: {
    ...mapActions("storefronts", ["updateStorefront", "getStorefront"]),

    async editStorefront() {
      const isValid = await this.$refs.editStorefrontForm.validate();
      if (!isValid) {
        return;
      }

      let storefront = new FormData();

      storefront.append("title", this.title);
      storefront.append("key", this.key);
      storefront.append("parameters[][key]", "products_lifetime");
      storefront.append("parameters[][name]", "Время жизни товаров (в днях)");
      storefront.append("parameters[][value]", this.lifetime);

      if (this.cover && typeof this.cover !== "string") {
        storefront.append("cover", this.cover);
      }

      this.$store.commit("SET_SHOW_LOADER", true);
      let success = await this.updateStorefront({
        id: this.initStorefront.id,
        storefront,
      });

      if (success) {
        this.$router.push("/storefronts");
      }
      this.$store.commit("SET_SHOW_LOADER", false);
    },

    validateLifetime(event) {
      if (event.target.value < 1) {
        this.lifetime = 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-wrap {
  display: flex;
  justify-content: space-between;
}

.image-wrapper {
  position: relative;
  max-width: 150px;

  &:hover {
    &::before,
    .remove-icon {
      opacity: 1;
      visibility: visible;
    }
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    background: rgba(0, 0, 0, 0.7);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s;
  }

  .remove-icon {
    position: absolute;
    right: 3px;
    top: 3px;
    width: 24px;
    height: 24px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s;
    cursor: pointer;

    & > * {
      color: red;
    }
  }
}
</style>